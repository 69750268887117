/* GENERAL */

.dropdown_icon {
    font-size: 1.2rem;
    color: #6c757d;
    float: right;
}

.permissionGroupAction {
    margin-right: 5px;
}

.action_menu {
    font-size: 0.8rem;
}

.action_menu_list {
    font-size: 0.8rem;
    margin-right: 1px;
}

.action_menu_list_disabled {
    font-size: 0.8rem;
    margin-right: 1px;
    cursor: not-allowed;
}

.active_switch .custom-control-label {
    cursor: pointer;
}

.active_switch_disabled .custom-control-label {
    cursor: not-allowed;
}
